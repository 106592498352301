import JSONTable, { makeEpoch } from 'components/common/JSONTable'
import { JSONObject, JSONValue, OvenData } from 'utils/oatsApi'

type FieldNames =
  | 'thingGroupNames'
  | 'rssi'
  | 'ovenState'
  | 'ipAddress'
  | 'firmwareVersion'
  | 'configVersion'
  | 'serialNumber'
  | 'deviceModel'
  | 'configGroup'
  | 'thingLogs'
  | 'thingPage'
  | 'connectivity'

type Props = {
  oven?: OvenData
  includePageLink?: boolean
  error?: string
  loading?: boolean
  hidden?: FieldNames[]
}

const OvenDataViewer = ({
  oven,
  includePageLink,
  error,
  loading,
  hidden,
}: Props) => {
  let data: JSONObject = oven && !error && !loading ? (oven as JSONObject) : {}

  if (error) {
    data = {
      error,
    }

    return (
      <div className="rounded-lg overflow-clip bg-slate-50 shadow-lg">
        <JSONTable data={data} defaultOpen={['error']} />
      </div>
    )
  }

  if (loading) {
    data = {
      loading,
    }

    return (
      <div className="rounded-lg overflow-clip bg-slate-50 shadow-lg">
        <JSONTable data={data} defaultOpen={['loading']} />
      </div>
    )
  }

  if (!oven) {
    return (
      <div className="rounded-lg overflow-clip bg-slate-50 shadow-lg">
        <JSONTable data={data} defaultOpen={['error']} />
      </div>
    )
  }

  // raw data
  data = {
    rawData: { ...(oven as JSONObject) },
  }

  if (includePageLink && oven) {
    data = {
      pageLink: `${window.location.origin}/ovens/${oven.thingName}`,
      ...data,
    }
  }

  // // Thing type name
  // if (oven.thingTypeName) {
  //   data = {
  //     thingTypeName: oven.thingTypeName as JSONValue,
  //     ...data,
  //   }
  // }

  // // Thing Name
  // if (oven.thingName) {
  //   data = {
  //     thingName: oven.thingName as JSONValue,
  //     ...data,
  //   }
  // }

  // // Thing Id
  // if (oven.thingId) {
  //   data = {
  //     thingId: oven.thingId as JSONValue,
  //     ...data,
  //   }
  // }

  // Thing group names
  if (oven.thingGroupNames && !hidden?.includes('thingGroupNames')) {
    data = {
      thingGroupNames: oven.thingGroupNames as JSONValue,
      ...data,
    }
  }

  // Rssi
  if (
    oven.shadow &&
    oven.shadow.reported &&
    oven.shadow.reported.rssi &&
    !hidden?.includes('rssi')
  ) {
    data = {
      rssi: oven.shadow.reported.rssi as JSONValue,
      ...data,
    }
  }

  // Oven State
  if (
    oven.shadow &&
    oven.shadow.reported &&
    oven.shadow.reported.ovenState &&
    !hidden?.includes('ovenState')
  ) {
    data = {
      ovenState: oven.shadow.reported.ovenState as JSONValue,
      ...data,
    }
  }

  // Ip Address
  if (
    oven.shadow &&
    oven.shadow.reported &&
    oven.shadow.reported.ipAddress &&
    !hidden?.includes('ipAddress')
  ) {
    data = {
      ipAddress: oven.shadow.reported.ipAddress as JSONValue,
      ...data,
    }
  }

  // Firmware Version
  if (
    oven.shadow &&
    oven.shadow.reported &&
    oven.shadow.reported.firmwareVersion &&
    !hidden?.includes('firmwareVersion')
  ) {
    data = {
      firmwareVersion: oven.shadow.reported.firmwareVersion as JSONValue,
      ...data,
    }
  }

  // Config Version
  if (
    oven.shadow &&
    oven.shadow.reported &&
    oven.shadow.reported.configVersion &&
    !hidden?.includes('configVersion')
  ) {
    data = {
      configVersion: oven.shadow.reported.configVersion as JSONValue,
      ...data,
    }
  }

  // Serial Number
  if (
    oven.attributes &&
    oven.attributes.serialNumber &&
    !hidden?.includes('serialNumber')
  ) {
    data = {
      serialNumber: oven.attributes.serialNumber as JSONValue,
      ...data,
    }
  }

  // Device Model
  if (
    oven.attributes &&
    oven.attributes.deviceModel &&
    !hidden?.includes('deviceModel')
  ) {
    data = {
      deviceModel: oven.attributes.deviceModel as JSONValue,
      ...data,
    }
  }

  // Config Group
  if (
    oven.attributes &&
    oven.attributes.configGroup &&
    !hidden?.includes('configGroup')
  ) {
    data = {
      configGroup: oven.attributes.configGroup as JSONValue,
      ...data,
    }
  }

  // Logs
  if (oven.aws_cloudwatch_url && !hidden?.includes('thingLogs')) {
    data = {
      thingLogs: oven.aws_cloudwatch_url as JSONValue,
      ...data,
    }
  }

  // Thing page
  if (oven.aws_thing_url && !hidden?.includes('thingPage')) {
    data = {
      thingPage: oven.aws_thing_url as JSONValue,
      ...data,
    }
  }

  // Connectivity
  if (oven.connectivity && !hidden?.includes('connectivity')) {
    if (oven.connectivity.connected) {
      data = {
        connected: oven.connectivity.timestamp,
        ...data,
      }
    } else {
      data = {
        disconnected:
          makeEpoch(oven.connectivity.timestamp.toString()) +
          ' ' +
          oven.connectivity.disconnectReason,
        ...data,
      }
    }
  }

  return (
    <div className="rounded-lg overflow-clip bg-slate-50 shadow-lg">
      <JSONTable data={data} defaultOpen={['attributes']} />
    </div>
  )
}

export default OvenDataViewer
