import { CaretDownIcon, CaretUpIcon } from '@tovala/component-library'
import { useState } from 'react'
import { convertToPrettyString } from 'utils/stringUtils'
import { clsx } from 'clsx'
import { convertARNToURL } from 'utils/arnToURL'
import { JSONObject, JSONPrimitives, JSONValue } from 'utils/oatsApi'

export const JSONRow = ({
  rowKey,
  value,
  index,
  defaultOpen,
}: {
  rowKey: string
  value: JSONValue
  index: number
  defaultOpen?: string[]
}) => {
  const isThisOpen = defaultOpen && defaultOpen.includes(rowKey)
  const [dropdownState, setDropdownState] = useState<boolean>(
    isThisOpen || false,
  )

  // Try to simplify value
  if (typeof value === 'object' && !Array.isArray(value) && value) {
    const keys = Object.keys(value as JSONObject)
    if (keys.length === 1) {
      const key = keys[0]
      return (
        <JSONRow
          index={index}
          rowKey={convertToPrettyString(rowKey + ' ' + key)}
          value={(value as JSONObject)[key]}
        />
      )
    }
  }

  if (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    (value === null ||
      value === undefined ||
      Object.keys(value as JSONObject).length === 0)
  ) {
    return (
      <tr
        key={rowKey}
        className={index % 2 === 0 ? ' bg-gray-50' : ' bg-white'}
      >
        <td className="border-gray-200 px-4 py-2">
          {convertToPrettyString(rowKey)}
        </td>
        <td className="border-gray-200 px-4 py-2">
          <span className="text-gray-400">Empty</span>
        </td>
      </tr>
    )
  }

  if (Array.isArray(value) && value.length === 1) {
    return <JSONRow index={index} rowKey={rowKey} value={value[0]} />
  }

  const needsDropdown = typeof value === 'object' || Array.isArray(value)
  if (needsDropdown) {
    return (
      <>
        <tr
          key={rowKey + '-dropdown'}
          className={index % 2 === 0 ? ' bg-gray-50' : ' bg-white'}
        >
          <td
            className="border-t border-b border-gray-200 px-4 py-2 w-full"
            colSpan={2}
          >
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => {
                setDropdownState(!dropdownState)
              }}
            >
              <div>{convertToPrettyString(rowKey)}</div>
              <div className="w-4">
                {dropdownState ? <CaretDownIcon /> : <CaretUpIcon />}
              </div>
            </div>
          </td>
        </tr>
        {dropdownState && (
          <tr
            key={rowKey}
            className={clsx('w-full', {
              'bg-gray-50': index % 2 === 0,
              'bg-white': index % 2 !== 0,
            })}
          >
            <td
              className="border-t border-b border-gray-200 px-4 py-2 w-full"
              colSpan={2}
            >
              {typeof value === 'object' && !Array.isArray(value) && value && (
                <JSONTable data={value as JSONValue} />
              )}

              {typeof value === 'object' && Array.isArray(value) && value && (
                <JSONTable
                  data={
                    value
                      .map((v, i) => ({ [i]: v }))
                      .reduce((a, b) => ({ ...a, ...b }), {}) as JSONValue
                  }
                />
              )}
            </td>
          </tr>
        )}
      </>
    )
  }

  return (
    <tr key={rowKey} className={index % 2 === 0 ? ' bg-gray-50' : ' bg-white'}>
      <td className="border-gray-200 px-4 py-2">
        {convertToPrettyString(rowKey)}
      </td>
      <td className="border-gray-200 px-4 py-2">
        <JSONValueCell value={value as JSONPrimitives} />
      </td>
    </tr>
  )
}

const JSONTable = ({
  data,
  defaultOpen = [],
}: {
  data: JSONValue
  defaultOpen?: string[]
}) => {
  return (
    <div className="overflow-clip rounded-lg">
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead className="bg-orange-1 text-white">
            <tr>
              <th className="p-2 px-4 text-md">Key</th>
              <th className="p-2 px-4 text-md">Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data as JSONObject).map(([key, value], index) => (
              <JSONRow
                key={key}
                defaultOpen={defaultOpen}
                index={index}
                rowKey={key}
                value={value}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

function isUrl(value: JSONValue) {
  if (typeof value !== 'string') {
    return false
  }
  try {
    const url = new URL(value)
    if (url.origin === 'null' && !isARN(value)) {
      return false
    }
    return true
  } catch {
    return false
  }
}

function isARN(value: string) {
  return value.match(/arn:aws/) !== null
}

function cleanupARN(value: string) {
  return value.split('/').slice(-1)[0]
}

function cleanupUrl(url: string) {
  let newUrl = url
  newUrl = new URL(newUrl).hostname
  newUrl = newUrl.replace('www.', '')
  return newUrl
}

function makeUrl(value: string) {
  if (isARN(value)) {
    return makeArn(value)
  }
  return (
    <a
      className="text-blue-500 hover:underline"
      href={value}
      rel="noreferrer"
      target="_blank"
    >
      {cleanupUrl(value)}
    </a>
  )
}

function makeArn(value: string) {
  const arnUrl = convertARNToURL(value)
  if (!arnUrl) {
    return value
  }

  return (
    <a
      className="text-blue-500 hover:underline"
      href={arnUrl}
      rel="noreferrer"
      target="_blank"
    >
      {cleanupARN(value)}
    </a>
  )
}

function makeBoolean(value: JSONValue) {
  if (value === true) {
    return 'True'
  } else if (value === false) {
    return 'False'
  }

  return 'Unknown'
}

function isEpoch(value: string) {
  return value.match(/^\d{10}$/) || value.match(/^\d{13}$/)
}

export function makeEpoch(value: string) {
  return new Date(
    parseInt(value, 10) * (value.length === 10 ? 1000 : 1),
  ).toLocaleString()
}

const JSONValueCell = ({ value }: { value: JSONPrimitives }) => {
  if (typeof value === 'string' && isUrl(value)) {
    return makeUrl(value)
  }
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'boolean') {
    return makeBoolean(value)
  }
  if (typeof value === 'number' && isEpoch(value.toString())) {
    return makeEpoch(value.toString())
  }
  if (typeof value === 'number') {
    return value
  }
  return JSON.stringify(value)
}

export default JSONTable
